import React from "react";
import qs from "qs";
import Prismic from "prismic-javascript";

import { FlexCol, H1 } from "components";

import Home from "../index";
import Amenities from "../amenities";
import ArchitecturalGuidelines from "../news-and-resources/architectural-guidelines/";
import Community from "../community";
import Contact from "../contact-us";
import FAQS from "../news-and-resources/faqs";
import Gallery from "../news-and-resources/gallery";
import LotFinder from "../lot-finder";
import News from "../news-and-resources/news/";
import NewsPost from "../../layouts/news/";
import Showhomes from "../showhomes";

const linkResolver = function(doc) {
  return doc;
};

export default class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      preview: {},
      apiEndpoint: "https://anthem-wedderburn-landing.prismic.io/api/v2"
    };
  }

  componentDidMount() {
    this.preview(this.props);
  }

  preview = props => {
    const params = qs.parse(props.location.search.slice(1));
    Prismic.getApi(this.state.apiEndpoint).then(api => {
      api.previewSession(params.token, linkResolver, "/").then(document => {
        console.log(document);
        this.setState({ preview: document, loading: false });
      });
    });
  };

  render() {
    return (
      <>
        {this.state.loading && (
          <FlexCol minHeight="75vh" justifyContent="center" alignItems="center">
            <H1>Loading previews...</H1>
          </FlexCol>
        )}
        {!this.state.loading && (
          <FlexCol>
            {this.state.preview.type === "amenities" && (
              <Amenities preview={this.state.preview.data} />
            )}
            {this.state.preview.type === "community" && (
              <Community preview={this.state.preview.data} />
            )}
            {this.state.preview.type === "lot_finder" && (
              <LotFinder preview={this.state.preview.data} />
            )}
            {this.state.preview.type === "gallery" && (
              <Gallery preview={this.state.preview.data} />
            )}
            {this.state.preview.type === "home" && (
              <Home preview={this.state.preview.data} />
            )}
            {this.state.preview.type === "faqs" && (
              <FAQS preview={this.state.preview.data} />
            )}
            {this.state.preview.type === "architectural_guidelines" && (
              <ArchitecturalGuidelines preview={this.state.preview.data} />
            )}
            {this.state.preview.type === "news" && (
              <News preview={this.state.preview.data} />
            )}
            {this.state.preview.type === "news_post" && (
              <NewsPost preview={this.state.preview.data} />
            )}
            {this.state.preview.type === "contact" && (
              <Contact preview={this.state.preview.data} />
            )}
            {this.state.preview.type === "showhomes" && (
              <Showhomes preview={this.state.preview.data} />
            )}
          </FlexCol>
        )}
      </>
    );
  }
}
