import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { serializer, linkResolver } from "utils";
import {
  H1,
  PageHeader,
  Section,
  SEO,
  Button,
  RichText,
  FadeGroup,
} from "components";

const ArchitecturalGuidelines = ({ preview }) => (
  <StaticQuery
    query={graphql`
      {
        prismicArchitecturalGuidelines {
          dataString
          data {
            main_image {
              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      let page = JSON.parse(data.prismicArchitecturalGuidelines.dataString);
      if (preview) {
        page = preview;
      }
      return (
        <>
          <SEO
            image={page.seo_image && page.seo_image.url}
            title={page.seo_title && PrismicRichText.asText(page.seo_title)}
            description={
              page.seo_description &&
              PrismicRichText.asText(page.seo_description)
            }
          />
          <PageHeader
            bg="brand.nickel"
            src={preview ? page.main_image && page.main_image.url : undefined}
            fluid={
              preview
                ? undefined
                : data.prismicArchitecturalGuidelines.data.main_image &&
                  data.prismicArchitecturalGuidelines.data.main_image.fluid
            }
            alt={page.main_image && page.main_image.alt}
          />
          <Section pt={2} pb={2} bg="bg.default">
            <div
              css={`
                display: flex;
                justify-content: center;
                -ms-grid-column: 1;
                -ms-grid-column-span: 23;
                grid-column: 1 / span 12;
                -ms-grid-row: 1;
                grid-row: 1;
                ${(props) => props.theme.mediaQueries.medium} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 15;
                  grid-column: 1 / 9;
                }
                ${(props) => props.theme.mediaQueries.small} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 7;
                  grid-column: 1 / 5;
                }
              `}
            >
              <FadeGroup>
                <div
                  css={`
                    margin: 0 auto;
                    padding: 5vw;
                    border: 1px solid
                      ${(props) => props.theme.colors.bg.reverse};
                    text-align: center;
                    ${(props) => props.theme.mediaQueries.small} {
                      padding: 7.5vw;
                    }
                  `}
                >
                  <H1
                    children={page.title && PrismicRichText.asText(page.title)}
                    mb="0.2em"
                  />
                  <RichText
                    children={
                      page.description &&
                      PrismicRichText.render(
                        page.description,
                        linkResolver,
                        serializer
                      )
                    }
                    mb="2em"
                  />
                  <Button
                    children={
                      `Download - ` +
                      (page.pdf.size &&
                        (page.pdf.size / 1024 / 1024).toFixed(1)) +
                      " MB"
                    }
                    as="a"
                    href={page.pdf && page.pdf.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="peach"
                  />
                </div>
              </FadeGroup>
            </div>
          </Section>
        </>
      );
    }}
  />
);

export default ArchitecturalGuidelines;
