import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { RichText as PrismicRichText } from "prismic-reactjs";

import {
  AccordionGroup,
  H1,
  PageHeader,
  Section,
  SEO,
  FadeGroup,
  RichText,
} from "components";

import { columnPush, serializer, linkResolver } from "utils";

const FAQPage = ({ preview }) => (
  <StaticQuery
    query={graphql`
      {
        prismicFaqs {
          dataString
          data {
            main_image {
              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      let page = JSON.parse(data.prismicFaqs.dataString);
      if (preview) {
        page = preview;
      }
      return (
        <>
          <SEO
            image={page.seo_image && page.seo_image.url}
            title={page.seo_title && PrismicRichText.asText(page.seo_title)}
            description={
              page.seo_description &&
              PrismicRichText.asText(page.seo_description)
            }
          />
          <PageHeader
            bg="brand.navy"
            src={preview ? page.main_image && page.main_image.url : undefined}
            fluid={
              preview
                ? undefined
                : data.prismicFaqs.data.main_image &&
                  data.prismicFaqs.data.main_image.fluid
            }
            alt={page.main_image && page.main_image.alt}
          />
          <Section pt={2} pb={2} bg={["brand.navy", "bg.default"]}>
            <div
              css={`
                -ms-grid-column: 3;
                -ms-grid-column-span: 11;
                grid-column: 2 / 8;
                -ms-grid-row: 1;
                grid-row: 1;
                ${(props) => props.theme.mediaQueries.medium} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 15;
                  grid-column: 1 / 9;
                }
                ${(props) => props.theme.mediaQueries.small} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 7;
                  grid-column: 1 / 5;
                }
              `}
            >
              <div
                css={`
                  position: relative;
                  ${columnPush(1)};
                  ${(props) => props.theme.mediaQueries.small} {
                    padding-left: 0;
                  }
                `}
              >
                <FadeGroup>
                  <H1
                    children={page.title && PrismicRichText.asText(page.title)}
                    mb="0.5em"
                    color={["text.reverse", "text.default"]}
                  />
                  <RichText
                    children={
                      page.description &&
                      PrismicRichText.render(
                        page.description,
                        linkResolver,
                        serializer
                      )
                    }
                    color={["text.reverse", "text.default"]}
                  />
                </FadeGroup>
              </div>
            </div>
          </Section>
          <Section
            pb={3}
            bg="bg.default"
            css={`
              ${(props) => props.theme.mediaQueries.small} {
                padding-top: 15vw;
              }
            `}
          >
            <div
              css={`
                -ms-grid-column: 3;
                -ms-grid-column-span: 19;
                grid-column: 3 / 11;
                -ms-grid-row: 1;
                grid-row: 1;
                ${(props) => props.theme.mediaQueries.medium} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 15;
                  grid-column: 1 / 9;
                }
                ${(props) => props.theme.mediaQueries.small} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 7;
                  grid-column: 1 / 5;
                }
              `}
            >
              {page.faqs && (
                <AccordionGroup
                  id="questions"
                  items={page.faqs.map((question, index) => {
                    return {
                      id: "question" + index,
                      heading: question.question,
                      children: (
                        <RichText>
                          {question.answer &&
                            PrismicRichText.render(
                              question.answer,
                              linkResolver,
                              serializer
                            )}
                        </RichText>
                      ),
                    };
                  })}
                />
              )}
            </div>
          </Section>
        </>
      );
    }}
  />
);

export default FAQPage;
